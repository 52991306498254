.navbar {
    display: flex;
    justify-content: space-around;
    background-color: #000000;
    padding: 20px 0;
}

.nav-link {
    color: #666;
    text-decoration: none;
    font-size: 1.2em;
}

.nav-link:hover {
    color: #333;
    transition: color 0.3s ease;
}
