.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #000;
  color: #0ce1ac;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Courier New', monospace;
  position: relative;
  height: 100vh;
  margin: 0;
  padding: 0;
}

