/* Art.css */
.art-container {
    background: #000; /* Black background */
    color: #ffffff; /* Pink text color */
}

.masonry-grid {
    display: flex;
    flex-wrap: wrap; /* allows the items to wrap as needed */
    margin-left: -30px;
    width: auto;
}

.masonry-grid_column {
    flex: 1; /* this allows the column to grow and shrink as needed */
    padding-left: 30px;
    background-clip: padding-box;
}

.masonry-img {
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.masonry-img:hover {
    transform: scale(1.03);
}

@media (max-width: 600px) {
    .masonry-img {
        min-width: 33.33%; /* ensure images take up at least a third of the screen width */
    }
}

@media (min-width: 601px) and (max-width: 900px) {
    .masonry-img {
        min-width: 33.33%; /* ensure images take up at least a third of the screen width */
    }
}

@media (min-width: 901px) {
    .masonry-img {
        min-width: 33.33%; /* ensure images take up at least a third of the screen width */
    }
}
