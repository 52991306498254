
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Make the overlay more black */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    background: #000; /* Set the modal background to black */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 6px solid #ffffff; /* Add a pink border */
}

.modal-content img {
    max-height: 80vh;
    max-width: 80vw;
    object-fit: contain;
}


.close-button {
    box-shadow: inset 0px 1px 0px 0px #ffffff; /* Adjust shadow color to pink */
	background: linear-gradient(to bottom, #ffffff 5%, #ffffff 100%); /* Adjust gradient colors to pink */
	background-color: #ffffff; /* Adjust background color to pink */
	border-radius: 11px;
	border: 2px solid #ffffff; /* Adjust border color to pink */
	display: inline-block;
	cursor: pointer;
	color: #000000;
	font-family: Arial;
	font-size: 16px;
	font-weight: bold;
	padding: 8px 18px;
	text-decoration: none;
	text-shadow: 0px 1px 0px #000000; /* Adjust text shadow color to black */
    margin-top: 15px;
}

.close-button:hover {
	background: linear-gradient(to bottom, #ffffff 5%, #ffffff 100%); /* Adjust gradient colors to pink */
	background-color: #ffffff; /* Adjust background color to lighter pink */
}

.close-button:active {
	position:relative;
	top: 1px;
}
