.title-container {
  text-align: center;
  padding: 20px;
  background-color: #000000;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.title-container h1 {
  font-size: 4.6em;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 1em;
}

.title-container h3 {
  font-size: 2.0em;
  color: #ffffff;
  margin: 0.5em 0;
  transition: transform 0.3s ease;
}

.title-container h3 a {
  color: inherit;
  text-decoration: none;
}

.title-container h3 a:hover {
  color: #ff77c9;
  transform: scale(1.05);
}
